export default {
  watch: {
    //对所有的审查结论进行监听
    'formData.conclusion': 'watchConclusion',
    // 对部级预审的审查结论进行监听
    'formData.ministerial.conclusion': 'watchConclusion'
  },
  methods: {
    watchConclusion(val) {
      const ele = document.getElementsByClassName('footer')[0].getElementsByClassName('public-button')[0]
      if (val == 1) {
        ele.setAttribute('style', 'background:#4CB361')
      } else if (val == 2) {
        ele.setAttribute('style', 'background:#E05C69')
      }
    }
  }
}