<template>
  <div id="app">
    <topBox />
    <router-view />
    <hoverBox />
    <div class="mask" v-if="$store.state.showMask"></div>
  </div>
</template>

<script>
import topBox from '@/components/topBox.vue'
import hoverBox from '@/components/hoverBox.vue'

export default {
  components: {
    topBox,
    hoverBox,
  },
  data() {
    return {
      pageQuery: {
        pageIndex: 1,
        pageSize: 10,
        dateList: [],
        search: '',
      },
    }
  },
  methods: {
  },
  mounted() {
    if (
      localStorage.getItem("hccms_token") &&
      localStorage.getItem("hccms_token").length > 0
    ) {
      this.$apis.getMineInfo().then(res => {
        this.$store.commit('setMineInfo', res.body)
        this.$store.commit('setNowTime', this.$options.filters['dateformat'](res.now, 'yyyy-MM-DD'))
      })
    }
  },
}
</script>

<style lang="less">
#app {
  width: 100vw;
  min-width: 1440px;
  height: 100vh;

  .mask {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .35);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 998;
  }
}

.hideIcon .el-collapse-item__arrow.el-icon-arrow-right {
  color: transparent !important;
}

body {
  font-family: 'SYHT2', Avenir, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #383838;
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  .el-tooltip__popper[x-placement^='top-start'] .popper__arrow {
    border-top-color: #E5DEEC !important;
  }

  .el-tooltip__popper[x-placement^='top-start'] .popper__arrow::after {
    border-top-color: #E5DEEC !important;
  }

  .el-tooltip__popper[x-placement^='top'] .popper__arrow {
    border-top-color: #E5DEEC !important;
  }

  .el-tooltip__popper[x-placement^='top'] .popper__arrow::after {
    border-top-color: #E5DEEC !important;
  }

  .el-tooltip__popper.is-dark {
    font-size: 16px !important;
    background: #E5DEEC !important;
    color: #8B7A9B !important;
  }

  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #8B7A9B !important;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-color: #8B7A9B !important;
    background-color: #8B7A9B !important;
  }

  .remove-empty .el-select-dropdown__empty,
  .remove-empty .popper__arrow,
  .el-select-dropdown.el-popper.remove-empty {
    display: none !important;
  }

  .el-date-table td.in-range div,
  .el-date-table td.in-range div:hover,
  .el-date-table.is-week-mode .el-date-table__row.current div,
  .el-date-table.is-week-mode .el-date-table__row:hover div {
    background-color: #E5DEEC !important;
  }

  .el-date-table td.end-date span,
  .el-date-table td.start-date span {
    background-color: #674B6D !important;
  }

  .el-date-table td span {
    border-radius: 3px !important;
  }


  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #674B6D !important;
    color: #fff !important;
  }

  .el-select-dropdown__item.selected {
    background-color: #674B6D !important;
    color: #fff !important;
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
    color: #674B6D;

    &:hover {
      color: #7E6F9D;
    }
  }

  .page {
    width: 100vw;
    min-width: 1440px;
    height: calc(100% - 60px);
    background-color: rgb(250, 248, 252);
    overflow-y: auto;
  }
}

// 共用样式
.form-box {
  padding: 0 40px;

  .form-label {
    height: 40px;
    line-height: 40px;

    .label-type {
      display: inline-block;
      transform: translateY(-5px);
      margin-right: 5px;
    }
  }

  .form-input {
    margin-bottom: 20px;
    position: relative;
  }
}

.remind-point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #C95C67;
  display: inline-block;
  position: absolute;
  right: 8px;
  top: 8px;
}

.readobly {
  width: calc(100vw - 365px) !important;
  height: calc(100vh - 295px) !important;
  min-width: 0 !important;
  margin-top: 20px;
  border-radius: 10px;
  overflow-x: hidden;
  background-color: #fff !important;
}

.right {
  float: right;
}

.double-btn {
  display: flex;

  &.main {
    background-color: #674B6D;
    color: #fff;

    .t-btn {
      color: #fff;
      text-decoration: none;
    }
  }

  .el-divider.el-divider--vertical {
    height: 40px !important;
    background-color: #D8D8D8 !important;
    width: 0.5px !important;
  }
}

.t-btn {
  cursor: pointer;
  display: inline-block;
  flex: 1;
  line-height: 40px;
  text-decoration: underline;
  color: #674B6D;
}

.star {
  text-decoration: none;
  color: #C95C67;
}

.input-cell {
  display: inline-block;
  flex: 1;
  line-height: 40px;
  border: 0.5px red solid;
}

.popover-box {
  .row {
    height: 40px;
    line-height: 40px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 18px;
    text-align: center;

    .row-icon {
      margin-right: 10px;
      font-size: 20px;
    }

    &:hover {
      background-color: #674B6D;
      color: #fff;
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }
}

// 思源粗体
.syht-bold {
  font-family: 'SYHT1' !important;
}

.table-header {
  width: 100%;
  height: 100px;
  color: #1C1C1C;

  .title {
    height: 80px;
    line-height: 80px;
    font-size: 20px;
    border-bottom: 1px solid #D8D8D8;
    padding: 0 40px;

    .table-name {
      font-size: 26px;
    }

    .tips {
      float: right;
      font-family: 'SYHT2' !important;
    }
  }
}

.label-tip {
  font-size: 14px;
  color: #8B7A9B;
  margin-top: 10px;
}

// 通用列表页样式
.listPage {
  width: calc(100% - 20px);
  min-height: calc(100vh - 100px);
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid transparent;

  .listPage-top {
    margin: 20px;

    &::after {
      content: "";
      width: 100%;
      height: 0;
      display: block;
      line-height: 0;
      font-size: 0;
      clear: both;
    }

    .selectbox {
      display: inline-block;
      margin-right: 20px;
    }

    .input-select-box {
      width: 100%;
      height: 0px;
      border-radius: 10px;
      position: absolute;
      bottom: 0px;
      left: 0;
      z-index: 99999;
      background-color: #fff;
      transition: all .5s linear .2s;
      overflow-y: auto;

      &.open {
        bottom: -130px;
        height: 130px;
        border: 1px solid #d8d8d8;
        padding: 10px;
      }

      &.big {
        height: 175px !important;
        bottom: -175px !important;
      }

      .select-item {
        height: 30px;
        line-height: 30px;
        border-radius: 10px;
        background-color: #D8D8D8;
        padding: 0 10px;
        margin-right: 10px;
        text-align: center;
        display: inline-block;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }

    .close-btn {
      position: absolute;
      right: 60px;
      top: 10px;
      font-size: 20px;
      color: #d8d8d8;
      cursor: pointer;
    }

    .search-btn {
      position: absolute;
      right: 10px;
      top: 5px;
      font-size: 20px;
      color: #8B7A9B;
      width: 41px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 10px;
      display: inline-block;
      transition: background-color .2s linear;
      cursor: pointer;

      &.focus-search {
        background-color: #8B7A9B;
        color: #fff
      }
    }
  }
}

.table-body {
  height: calc(100% - 200px);
  overflow-y: auto;
  padding: 0 40px;
  padding-bottom: 40px;
  width: 100%;
  overflow-x: hidden;

  .table-item-title {
    height: 40px;
    line-height: 40px;
    position: relative;
    margin-top: 20px;

    >.link {
      position: absolute;
      top: 0;
      right: 0;
      font-family: 'SYHT-2';
    }
  }
}

.el-table {
  font-size: 16px !important;
  color: #383838 !important;
}

.el-table th.el-table__cell {
  background-color: #F6F5F7 !important;
  height: 40px;
  color: #383838;
  font-weight: normal;
}

.el-table .el-table__cell {
  padding: 0 !important;
  text-align: center !important;
}

.el-table .cell {
  padding: 0 !important;
  min-height: 40px !important;
  line-height: 40px !important;
}

.table-footer {
  height: 100px;
  line-height: 100px;
  border-top: 1px solid #D8D8D8;
  text-align: center;
}

.dialog-header {
  height: 80px;
  border-bottom: 0.5px solid #D8D8D8;
  font-size: 20px;
  line-height: 60px;
  padding-top: 20px;
  padding-left: 40px;
}

.dialog-body {
  padding: 40px;
  border-bottom: 0.5px solid #D8D8D8;
  height: calc(100vh - 380px);
  overflow-y: auto;

  .table-form-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 40px;

    .item-label {
      margin-bottom: 10px;
    }
  }

  .del-btn {
    transform: translateY(-10px);
    display: inline-block;
    vertical-align: bottom;
  }
}

.map-body {
  width: 100%;
  height: calc(100% - 80px);

  .header-title {
    height: 40px;
    line-height: 40px;
    background-color: #8B7A9B;
    color: #fff;
    font-size: 18px;
    padding: 0 10px;
    position: relative;

    .close-btn {
      color: #ddd;
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 0px;

      &:hover {
        color: #fff;
      }
    }
  }
}

.dialog-footer {
  height: 100px;
  line-height: 100px;
  text-align: right;
  padding: 0 40px;

  &.map-footer {
    height: 80px;
    line-height: 80px;
    text-align: center;
  }
}

/* 处理ie输入框多余叉叉符号*/
input::-ms-clear,
input::-ms-reveal {
  display: none;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #D8D8D8;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
/*定义滚动条轨道 内阴影+圆角*/
*::-webkit-scrollbar-thumb {
  background-color: #a1a3a9;
  border-radius: 6px;
  width: 2px;
  height: 2px;
}

/*定义滑块 内阴影+圆角*/
.scrollbarHide::-webkit-scrollbar {
  display: none;
}

.scrollbarShow::-webkit-scrollbar {
  display: block;
}


/* element样式修改 */
.el-button {
  border-radius: 10px !important;
  height: 40px !important;
  min-width: 90px !important;
}

.tooltip-div {
  font-size: 16px;
  max-width: 600px;

  >div {
    line-height: 20px;
  }
}

// 菜单css
.el-icon-caret-top {
  position: absolute;
  right: 19px;
  top: 22px;
}

.menuList {
  width: 300px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 10px;

  .el-menu-vertical-demo {
    padding: 20px 20px 0 20px;
    margin: 10px 0;
    color: #383838;

    .secondMenu .el-submenu__title {
      width: 230px !important;
      margin-left: 30px !important;
      padding-left: 10px !important;
    }

    .el-icon-arrow-down:before {
      display: none;
      content: "";
      font-family: iconfont;
    }

    .el-menu-item-group__title {
      display: none;
    }

    .el-submenu.is-opened>.el-submenu__title .el-icon-caret-top {
      transform: rotate(180deg);
    }

    li.el-submenu>.el-submenu__title,
    .el-menu-item {
      width: 260px;
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      color: #383838;
      border-radius: 10px;
      margin-top: 10px;

      >i {
        color: #383838;
        font-size: 24px;
      }
    }

    .el-menu-item-group .el-menu-item {
      // Awant
      width: 210px;
      height: 60px;
      margin-left: 60px;
      margin-bottom: 10px;
    }

    .el-menu-item.is-active,
    .el-menu-item.is-active i,
    .el-menu-item:hover,
    .el-submenu__title:hover,
    li.el-submenu.is-active>.el-submenu__title {
      border-radius: 10px;
      color: #fff;
      background-color: #674b6d;
    }

    .el-menu-item:hover i,
    .el-submenu .el-submenu__title:hover i,
    .el-submenu .el-submenu__title:hover span,
    li.el-submenu.is-active>.el-submenu__title i {
      color: #fff;
    }
  }
}

.el-input__inner {
  border-radius: 10px !important;
  font-size: 16px !important;
  color: #383838 !important;
  width: 100% !important;
}

.el-popover.el-popper {
  padding: 0 !important;
}

.el-date-editor {
  width: 100% !important;
}

.el-avatar,
.el-cascader-panel,
.el-radio,
.el-radio--medium.is-bordered .el-radio__label,
.el-radio__label {
  font-size: 16px !important;
}

.el-textarea.is-disabled .el-textarea__inner {
  background-color: #f6f5f7 !important;
  color: #1C1C1C !important;
}

.el-textarea__inner {
  font-size: 16px !important;
}

.t-btn .el-upload,
.add-btn .el-upload {
  width: 100% !important;
}

.planing-page .add-btn {
  width: calc(100% - 40px) !important;
  margin-left: 20px !important;
}

.el-message-box__btns .el-button--primary {
  background-color: #8B7A9B !important;
  border-color: #8B7A9B !important;
}

.el-divider.el-divider--horizontal {
  height: 0.5px !important;
}


.el-input-number,
.el-select {
  width: 100% !important;
}

.el-input-number .el-input__inner {
  padding: 5px 15px !important;
  text-align: left !important;
}

.el-input-number__decrease,
.el-input-number__increase {
  display: none !important;
}

.col-4 .el-input-number .el-input__inner {
  text-align: center !important;
}

.el-divider.el-divider--horizontal {
  width: calc(100% + 40px) !important;
  transform: translateX(-20px) !important;
  margin: 40px 0 !important;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #674B6D !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #674B6D !important;
}

.el-upload--picture-card:hover,
.el-upload:focus {
  border-color: #fff !important;
  color: #fff !important;
}

.el-dialog {
  margin-top: 10vh !important;
  border-radius: 10px !important;
}

.el-dialog__header,
.el-dialog__footer {
  display: none !important;
}

.el-dialog__body {
  padding: 0 !important;
  overflow: hidden !important;
  border-radius: 10px !important;

  .round {
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    text-align: center;
  }

  p {
    line-height: 40px;

    .index_btn {
      width: 202px;
      height: 45px;
      display: inline-block;
      border-radius: 22.5px;
      background-color: #D3E7EB;
      color: #1D3444;
      cursor: pointer;
      margin: 0 20px;

      &.disabled {
        background-color: #EEEEEE;
        color: #9EA3A6;
        cursor: auto;
      }
    }
  }
}

// .el-radio__input.is-checked+.el-radio__label {
//   color: #8B7A9B !important;
// }

// .el-radio__input.is-checked .el-radio__inner {
//   border-color: #8B7A9B !important;
//   background-color: #8B7A9B !important;
// }

// 天地图样式
.tdt-label {
  border-radius: 50% !important;
  background-color: #674B6D !important;
  color: #fff !important;
}

.polygon-label .tdt-label {
  border-radius: 0 !important;
}

.el-tooltip .el-checkbox {
  margin: 0px 0px 0px 10px !important;
  line-height: 16px !important;
}
// radio单选框样式
.el-radio-group{
  .el-radio{
    padding: 0 10px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px 16px 16px 16px;
    .el-radio__input{
      display: inline-block;
    }
    &.is-checked{
      background: #4CB361;
      .el-radio__label{
        color: #fff !important;
      }
      .el-radio__inner{
        border: none;
        width: 16px;
        height: 16px;
        &:after{
          width: 8px;
          height: 8px;
          background: #4CB361 !important;
        }
      }
    }
  
    &:nth-child(2){
      &.is-checked{
        background: #E05C69;
        .el-radio__inner{
          &:after{
            background: #E05C69 !important;
          }
        }
      }
      
    }
  }
}

</style>
